import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { RichTextJsonContent } from '@pafcloud/rich-text';
import { ReadMore } from '@pafcloud/base-components';
import { Color, Font } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { PageSeoTextColors } from './page-seo-text-colors';
import type { PageSeoText_data$key } from './__generated__/PageSeoText_data.graphql';

const SeoText = styled.div({
  background: PageSeoTextColors.SeoBackground,
  padding: '64px var(--page-margin)',
});

const SeoReadMore = styled(ReadMore)({
  maxWidth: '70ex',
  margin: '0 auto',
  lineHeight: 1.5,
  color: PageSeoTextColors.SeoText,

  'h1:first-of-type': {
    marginTop: 0,
  },

  'h1, h2, h3, h4, h5, h6': {
    margin: 0,
    marginTop: '2rem',
    fontFamily: Font.HeadingAlt,
    fontWeight: $buildEnv.theme === 'classic' ? 'normal' : undefined,
    lineHeight: 1.2,
    color: 'inherit',
  },

  'p, li, blockquote': {
    margin: 0,
    marginTop: '0.25rem',
    color: 'inherit',
  },

  a: {
    color: Color.TextColors.LinkText,
  },
});

const pageSeoTextFragment = graphql`
  fragment PageSeoText_data on Page {
    seo {
      seoText {
        json
      }
    }
  }
`;

type PageSeoTextProps = {
  data: PageSeoText_data$key;
};

export const PageSeoText: FC<PageSeoTextProps> = (props) => {
  const data = useFragment(pageSeoTextFragment, props.data);

  if (data.seo?.seoText == null) {
    return null;
  }

  return (
    <SeoText>
      <SeoReadMore>
        <RichTextJsonContent content={data.seo?.seoText} />
      </SeoReadMore>
    </SeoText>
  );
};
