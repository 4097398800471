import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { Color } from '@pafcloud/style';
import { NoStyleButton } from '../button';

type ReadMoreProps = {
  children: ReactNode;
  openButtonText?: string;
  closeButtonText?: string;
  className?: string;
};

const ReadMoreContainer = styled.div({
  position: 'relative',
  overflow: 'hidden',
  marginBottom: '2rem',
  padding: 0,
});

type ReadMoreTextBlockProps = {
  open: boolean;
};

const ReadMoreTextBlock = styled.div<ReadMoreTextBlockProps>(({ open }) => ({
  maxHeight: open ? 'initial' : '10rem',
  maskImage: open ? 'none' : 'linear-gradient(to bottom, black 50%, rgba(0,0,0,.35) 70%, transparent 90%)',
  margin: 'auto',
  cursor: open ? undefined : 'pointer',

  'p:last-child': {
    marginBottom: 24,
  },
}));

const ReadMoreButton = styled(NoStyleButton)({
  margin: '0 0 1px 1px', // The border gets cut off without this
  padding: 2,
  fontSize: '1em',
  fontWeight: 'bold',
  color: Color.TextColors.LinkText,

  ':hover, :active, :focus': {
    color: Color.TextColors.LinkText,
  },
});

export const ReadMore: FC<ReadMoreProps> = ({ openButtonText, closeButtonText, children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['common']);
  const readMoreText = openButtonText ? openButtonText : t('read-more.open');
  const closeText = closeButtonText ? closeButtonText : t('read-more.close');

  return (
    <ReadMoreContainer className={className}>
      <ReadMoreTextBlock open={isOpen} onClick={() => (!isOpen ? setIsOpen((open) => !open) : undefined)}>
        {children}
      </ReadMoreTextBlock>
      <ReadMoreButton onClick={() => setIsOpen((open) => !open)}>{isOpen ? closeText : readMoreText}</ReadMoreButton>
    </ReadMoreContainer>
  );
};
