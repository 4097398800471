/**
 * @generated SignedSource<<79c26d97f30a2819e40f96fd11af2161>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageSeoText_data$data = {
  readonly seo: {
    readonly seoText: {
      readonly json: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "PageSeoText_data";
};
export type PageSeoText_data$key = {
  readonly " $data"?: PageSeoText_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PageSeoText_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageSeoText_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageSEO",
      "kind": "LinkedField",
      "name": "seo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Json",
          "kind": "LinkedField",
          "name": "seoText",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "json",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Page",
  "abstractKey": null
};

(node as any).hash = "3447e2033f8eb8fb88714ddd5330384e";

export default node;
